<script setup lang="ts">
import { DateFormatter, fromDate, getLocalTimeZone, type DateValue } from "@internationalized/date";
import { formatDate } from "@vueuse/core";
import { cn } from "~/lib/utils";

const props = withDefaults(
  defineProps<{
    name?: string;
    hasTime?: boolean;
    hasInput?: boolean;
  }>(),
  {
    hasTime: false,
    hasInput: true,
  }
);

const df = new DateFormatter("it-IT", {
  dateStyle: "long",
  timeStyle: props.hasTime ? "short" : undefined,
});

type DateTime = {
  hours: number;
  minutes: number;
};

const model = defineModel<Date | string | null>();
const value = ref<DateValue>();

const inputDate = ref<string>();

const time = ref<DateTime>({
  hours: model.value ? new Date(model.value).getHours() : 0,
  minutes: model.value ? new Date(model.value).getMinutes() : 0,
});

updateValue(model.value);

watch(model, (val) => {
  updateValue(val);
});

function updateValue(val: Date | string | null) {
  if (val) {
    value.value = fromDate(new Date(model.value), getLocalTimeZone());
    inputDate.value = model.value ? formatDate(new Date(model.value), "YYYY-MM-DD") : "";
  } else {
    value.value = undefined;
  }
}

function updateModel(val: DateValue | undefined, t: DateTime, updateText: boolean = false) {
  if (!val) {
    model.value = null;
    value.value = undefined;
    time.value.hours = 0;
    time.value.minutes = 0;
    inputDate.value = "";

    return;
  }

  const toDate = val?.toDate(getLocalTimeZone());
  if (props.hasTime) toDate?.setHours(t.hours, t.minutes, 0, 0);
  else toDate?.setHours(12, 0, 0, 0);

  model.value = toDate;
  value.value = val;

  if (updateText) inputDate.value = formatDate(toDate, "YYYY-MM-DD");
}

watch(time.value, (val) => {
  updateModel(value.value, val);
});
</script>

<template>
  <div v-bind="$attrs">
    <input v-if="name" type="hidden" :name="name" :value="model" />

    <Popover @update:open="(o) => o && $nextTick(() => $refs.dateInput?.$el.focus())">
      <PopoverTrigger as-child>
        <Button variant="outline" :class="cn('w-full justify-start text-left font-normal', !value && 'text-muted-foreground')">
          <Icon name="carbon:calendar" class="mr-2 h-4 w-4" />
          <span class="flex-1">
            {{ model ? df.format(new Date(model)) : "Seleziona una data" }}
          </span>
          <Icon
            v-if="value"
            name="carbon:close"
            @click.stop="
              () => {
                updateModel(undefined, { hours: 0, minutes: 0 });
              }
            "
            class="ml-2 h-4 w-4"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent class="w-auto p-0" align="start">
        <Calendar locale="it-IT" :model-value="value" @update:model-value="(val) => updateModel(val, time, true)" initial-focus />

        <div class="p-3" v-if="hasInput">
          <Label for="date">Data</Label>
          <Input
            ref="dateInput"
            type="date"
            class="hide-date-input"
            :default-value="inputDate"
            @update:model-value="
              (val) => {
                updateModel(val.toString().length ? fromDate(new Date(val), getLocalTimeZone()) : undefined, time);
              }
            "
          />
        </div>

        <div class="p-3 pt-0" v-if="hasTime">
          <Label for="time">Ore</Label>
          <div class="grid grid-cols-2 gap-1">
            <Input type="number" min="0" max="23" v-model="time.hours" />
            <Input type="number" min="0" max="59" v-model="time.minutes" />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  </div>
</template>

<style>
.hide-date-input::-webkit-inner-spin-button {
  display: none;
}
.hide-date-input::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
